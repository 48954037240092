import React from 'react'

export default function MyAccountCard({ user }) {
  const accountInfo = user
  const plaidAccountDetails = user?.plaid_account_details

  const dispursementDateString = new Date(user.disbursement_date)
  const date = new Date(dispursementDateString)
  const formattedDisDate = dispursementDateString.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })

  const payoffDateString = new Date(user.term_information.scheduled_payoff_date)
  const formattedPayDate = payoffDateString.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })

  const num = plaidAccountDetails && plaidAccountDetails.account
  const lastFourDigits = num && num.slice(-4)

  const accountDisplay = '****' + user.loan_id.slice(-4)

  function numberWithCommasAndDecimals(number) {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  return (
    <div className='flex flex-col h-full min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0'>
      <div className='rounded-t bg-white px-6 py-6'>
        <div className='text-center flex justify-between'>
          <h6 className='text-blueGray-700 text-xl mb-3 font-bold'>Personal Loan ({accountDisplay})</h6>
        </div>

        <div className='flex-auto px-4 lg:px-10 py-10 pt-0'>
          <h6 className='text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase'>Loan Details</h6>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-6/12 px-4'>
              <div className='relative w-full mb-3'>
                <label className='block uppercase text-blueGray-600 text-xs font-bold mb-2' htmlFor='grid-password'>
                  Dispursment Date
                </label>
                <a>{formattedDisDate}</a>
              </div>
            </div>
            <div className='w-full lg:w-6/12 px-4'>
              <div className='relative w-full mb-3'>
                <label className='block uppercase text-blueGray-600 text-xs font-bold mb-2' htmlFor='grid-password'>
                  Venue Location
                </label>
                <a>{accountInfo.venue_location}</a>
              </div>
            </div>
            <div className='w-full lg:w-6/12 px-4'>
              <div className='relative w-full mb-3'>
                <label className='block uppercase text-blueGray-600 text-xs font-bold mb-2' htmlFor='grid-password'>
                  Original Balance
                </label>
                <a>${numberWithCommasAndDecimals(accountInfo.total_amount / 100)}</a>
              </div>
            </div>
            <div className='w-full lg:w-6/12 px-4'>
              <div className='relative w-full mb-3'>
                <label className='block uppercase text-blueGray-600 text-xs font-bold mb-2' htmlFor='grid-password'>
                  Remaining Balance
                </label>
                <a>${numberWithCommasAndDecimals(accountInfo.balance / 100)}</a>
              </div>
            </div>
          </div>

          <hr className='mt-6 border-b-1 border-blueGray-300' />

          <h6 className='text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase'>APR Information </h6>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-6/12 px-4'>
              <div className='relative w-full mb-3'>
                <label className='block uppercase text-blueGray-600 text-xs font-bold mb-2' htmlFor='grid-password'>
                  Type{' '}
                </label>
                <a className='capitalize'>{accountInfo.apr_type}</a>
              </div>
            </div>
            <div className='w-full lg:w-6/12 px-4'>
              <div className='relative w-full mb-3'>
                <label className='block uppercase text-blueGray-600 text-xs font-bold mb-2' htmlFor='grid-password'>
                  Rate{' '}
                </label>
                <a>{numberWithCommasAndDecimals(accountInfo.apr / 100)}%</a>
              </div>
            </div>
          </div>

          <hr className='mt-6 border-b-1 border-blueGray-300' />

          <h6 className='text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase'>Term Information</h6>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-4/12 px-4'>
              <div className='relative w-full mb-3'>
                <label className='block uppercase text-blueGray-600 text-xs font-bold mb-2' htmlFor='grid-password'>
                  Original Term
                </label>
                <a>{accountInfo.term_information.original_term} Months</a>
              </div>
            </div>
            <div className='w-full lg:w-4/12 px-2'>
              <div className='relative w-full mb-3'>
                <label className='block uppercase text-blueGray-600 text-xs font-bold mb-2' htmlFor='grid-password'>
                  Remaining Term
                </label>
                <a>{accountInfo.term_information.remaining_term} Months</a>
              </div>
            </div>
            <div className='w-full lg:w-4/12 px-0'>
              <div className='relative w-full mb-3'>
                <label className='block uppercase text-blueGray-600 text-xs font-bold mb-2' htmlFor='grid-password'>
                  Scheduled Payoff Date
                </label>
                <a>{formattedPayDate}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
