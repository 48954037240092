import React, { useState, useEffect } from 'react'
import { post } from '../../../lib/fetch'
import { useNavigate } from 'react-router-dom'
import { upsertPaymentInformation } from '../../../services/DataAccess'
function PaymentDetails({ onPress, open, ready, plaidAccountDetails, plaidAccountInformation, user }) {
  const [paymentProcessing, setPaymentProcessing] = useState(false)
  const [submittedPaymentId, setSubmittedPaymentId] = useState(null)
  const [paymentAmount, setPaymentAmount] = useState()
  const [otherAmountChecked, setOtherAmountChecked] = useState(false)
  const navigate = useNavigate()
  const generatePaymentId = () => {
    const min = 100000000000 // Minimum 12-digit integer (10^11)
    const max = 999999999999 // Maximum 12-digit integer (10^12 - 1)
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  useEffect(() => {}, [plaidAccountDetails, plaidAccountInformation])

  if (!user || user.length === 0) {
    return <div>Loading...</div>
  }

  const num = user?.plaid_account_details?.account
  const lastFourDigits = num ? num.slice(-4) : '****'
  const handlePayment = () => {
    setPaymentProcessing(true)
    handlePaymentSubmission()
    sendNotification()
  }

  const sendNotification = () => {
    const payload = {
      channel: 'payments',
      message: 'new payment submitted'
    }
    // change to backend variable
    post('https://marley-backend.herokuapp.com/send-notification', payload)
      .then((response) => {})
      .catch((error) => {})
  }

  const accountName = user?.plaid_account_information?.name
  const accountDisplay = accountName ? accountName.substring(0, 5) + '****' + lastFourDigits : 'No Account Connected'

  const dateString = user.next_payment_due_date
  const date = new Date(dateString)
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })
  const minPaymentAmount = user.next_payment_amount / 100

  const handlePaymentSubmission = async () => {
    const paymentInformation = {
      user_id: user.id,
      payment_id: generatePaymentId(),
      payment_date: new Date().toISOString(),
      amount: paymentAmount,
      account_details: user.plaid_account_details,
      account_information: user.plaid_account_information
    }

    const { data, error } = await upsertPaymentInformation(user, paymentInformation)

    if (error) {
    } else {
      setSubmittedPaymentId(paymentInformation.payment_id)
    }
  }

  const handleCheckboxChange = (amount) => {
    if (otherAmountChecked) {
      setPaymentAmount(null)
      setOtherAmountChecked(false)
    } else {
      setPaymentAmount(amount)
    }
  }

  const handleOtherAmountChange = (e) => {
    setPaymentAmount(parseFloat(e.target.value))
    setOtherAmountChecked(true)
  }

  function numberWithCommasAndDecimals(number) {
    return number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }

  return (
    <div className='w-auto mx-5'>
      {!paymentProcessing ? (
        <div>
          <div className='mb-3'>
            <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
              <div className='text-left'>
                <div className='flex flex-row items-center'>
                  <h2 className='font-bold mr-2'>Payment Due Date:</h2>
                  <h3 className='font-normal'>{formattedDate}</h3>
                </div>
                <div className='flex flex-row items-center'>
                  <h2 className='font-bold mr-2'>Remaining Balance:</h2>
                  <h3 className='font-normal'>${numberWithCommasAndDecimals(user.balance / 100)}</h3>
                </div>
                <div className='flex flex-row items-center'>
                  <h2 className='font-bold mr-2'>Account:</h2>
                  <h3 className='font-normal'>{user.plaid_account_information ? accountDisplay : 'No Account Connected'}</h3>
                </div>
              </div>
            </div>
          </div>

          <div>
            <h1 className='font-bold text-xl mb-2 text-left'>Choose How Much to Pay</h1>
            <form>
              <div className='grid grid-cols-1 gap-2 text-left'>
                <div className='flex items-start gap-4 border-2 border-gray-300 rounded p-2 mb-2'>
                  <div className='flex items-center mt-1'>
                    <input
                      type='radio'
                      onChange={() => handleCheckboxChange(minPaymentAmount)}
                      checked={paymentAmount === minPaymentAmount}
                    ></input>
                  </div>
                  <div className='grid grid-cols-1 gap-1'>
                    <a className='font-bold'>${numberWithCommasAndDecimals(minPaymentAmount)}</a>
                    <label>Minimum Payment Due</label>
                  </div>
                </div>
                <div className='flex items-start gap-4 border-2 border-gray-300 rounded p-2 mb-2'>
                  <div className='flex items-center mt-1'>
                    <input
                      type='radio'
                      onChange={() => handleCheckboxChange(user.latest_statement_balance)}
                      checked={paymentAmount === user.latest_statement_balance}
                    ></input>
                  </div>
                  <div className='grid grid-cols-1 gap-1'>
                    <a className='font-bold'>${numberWithCommasAndDecimals(user.latest_statement_balance / 100)}</a>
                    <label>Latest Statement Balance</label>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className='mt-2'>
            {!user.plaid_account_information ? (
              <button className='bg-black text-white font-bold rounded h-12 w-full' onClick={open} disabled={!ready}>
                Link Bank Account to Pay
              </button>
            ) : (
              <>
                <button className='bg-[#33334E] text-white font-bold rounded h-12 w-full mb-2' onClick={handlePayment}>
                  Submit Payment
                </button>
                <button
                  type='submit'
                  onClick={open}
                  disabled={!ready}
                  className='bg-white border-[#33334E] border-solid border-2 text-[#33334E] font-bold rounded h-10 w-full'
                >
                  Connect New Bank Account
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className='flex flex-col justify-center items-center h-full mt-8'>
          <div className='mb-4'>
            <h1>Thank you, we are now processing your payment.</h1>
            {submittedPaymentId && (
              <div className='text-s text-center my-5'>
                <h2 className='font-bold'>Payment ID</h2>
                <h2 className=''>{submittedPaymentId}</h2>
              </div>
            )}
          </div>
          <button className='bg-[#33334E] text-white font-bold rounded h-12 w-full mb-2' onClick={() => navigate('/user/profile')}>
            Home
          </button>
        </div>
      )}
    </div>
  )
}

export default PaymentDetails
