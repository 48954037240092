import React from 'react'
import EnableAutoPay from '../components/autopay-setup/EnableAutoPay'
import EditAutoPay from '../components/autopay-setup/EditAutoPay'
import { usePaymentHooks } from '../../../services/LoanPaymentService'
import { ContentLayout } from '../../../components/Layouts/ContentLayout'
const AutoPayPage = () => {
  const { open, ready, user } = usePaymentHooks()
  const plaidAccountDetails = user.plaid_account_details
  const plaidAccountInformation = user.plaid_account_information

  const autoPayProps = {
    open,
    ready,
    plaidAccountDetails,
    plaidAccountInformation,
    user
  }

  const auto_pay = user.auto_pay

  return (
    <ContentLayout>
      {auto_pay === false ? <EnableAutoPay {...autoPayProps} /> : <div> </div>}
      {plaidAccountInformation && auto_pay === true ? <EditAutoPay {...autoPayProps} /> : <div> </div>}
    </ContentLayout>
  )
}

export default AutoPayPage
