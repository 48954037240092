import { supabase } from '../config'
import React, { useState, useEffect, useContext, createContext, useMemo } from 'react'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
export const signInWithEmailAndPassword = async (email, password) => {
  return await supabase.auth.signInWithPassword({
    email,
    password
  })
}

const authContext = createContext()

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

const useProvideAuth = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  /*
    on auth events, the onAuthChange is being called twice, wrapping the debounce call in useMemo
    perserves the state of the function allowing debounce to working properly
    */

  const handleUser = useMemo(
    () =>
      debounce((session) => {
        handleUserHelper(session)
      }, 200),
    []
  )

  const refreshUser = async () => {
    if (!user || !user.id) return // ensure there's a user logged in before fetching

    const { data, error } = await supabase.from('profiles').select('*').eq('id', user.id)

    if (error) {
      console.error('Error refreshing user profile:', error)
      throw error
    } else {
      if (data && data.length > 0) {
        setUser(data[0])
        //console.log(data)
      } else {
        console.error('No user found on refresh.')
      }
    }
  }

  const handleUserHelper = async (session) => {
    if (session) {
      const { data, error } = await supabase.from('profiles').select('*').eq('id', session.user.id)

      if (error) {
        console.error('Error fetching user profile:', error)
        throw error
      } else {
        if (data && data.length > 0) {
          setUser(data[0])
          //console.log(data)

          return data[0]
        } else {
          console.error('No user, Logging out...')
          setUser(false)
          supabase.auth.signOut()
        }
      }
      setLoading(false)
      return false
    } else {
      setLoading(false)
      setUser(false)

      return false
    }
  }

  /*
    returns a promise containing user object or error
    */
  const signIn = async (email, password) => {
    setLoading(true)
    return await signInWithEmailAndPassword(email, password)
      .then(({ data: { session } }) => {
        setLoading(false)
        return handleUserHelper(session)
      })
      .catch((error) => {
        console.log('error in signing in', error)
        return error
      })
  }

  const signOut = () => {
    supabase.auth
      .signOut()
      .then(() => {
        handleUser(false)
      })
      .catch((error) => console.log('error logging out', error))
  }

  const signOutAndReturn = () => {
    supabase.auth
      .signOut()
      .then(() => {
        handleUser(false)
      })
      .then(navigate('/auth/login'))
      .catch((error) => console.log('error logging out', error))
  }

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      if (event !== 'SIGNED_IN') {
        // handled
        handleUser(session)
      }
      console.log(event)
    })
  }, [handleUser])

  return {
    user,
    loading,
    signIn,
    signOut,
    signOutAndReturn,
    refreshUser
  }
}
