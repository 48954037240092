import React, { useState, useEffect } from 'react'
import { supabase } from '../../../../config'
export default function PaymentsCard({ user }) {
  const [statements, setStatements] = useState()

  const fetchStatements = async () => {
    try {
      const { data, error } = await supabase.from('profiles').select('*').eq('id', user.id)

      if (error) {
        throw error
      }
      setStatements(data[0].statements)
    } catch (error) {
      console.error('Error fetching statements:', error)
    }
  }

  useEffect(() => {
    fetchStatements()
  }, [])

  return (
    <div className='relative flex flex-col min-w-0 break-words w-full mb-16 shadow-lg rounded-lg bg-blueGray-100 border-0'>
      <div className='rounded-t bg-white mb-0 px-6 py-6'>
        <div className='text-center flex justify-between'>
          <h6 className='text-blueGray-700 text-xl font-bold'>Statements</h6>
        </div>
      </div>

      <div className='flex-auto px-4 lg:px-10 py-10 pt-0'>
        <div className='flex flex-wrap mt-5 text-left'>
          <div className='w-full lg:w-12/12 px-4'>
            <div className='relative w-full mb-3'>
              {statements &&
                statements.map((statement, index) => (
                  <a
                    key={index}
                    className='block uppercase text-[#0000EE] underline text-xs font-bold my-2'
                    htmlFor='grid-password'
                    href={statement.statement_url}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {statement.statement_name}
                  </a>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
