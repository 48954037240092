import React, { useState } from 'react'
import LoginForm from '../components/LoginForm'
import { AuthLayout } from '../../../components/Layouts/AuthLayout'
import { useAuth } from '../../../lib/auth'
import { useNavigate } from 'react-router-dom'
import { getHomeFromUser } from '../../../routes'
export function Login() {
  const [loading, setLoading] = useState(false)
  const { signIn } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(e)
    await signIn(email, password)
      .then((user) => {
        if (user) {
          const home = getHomeFromUser(user)
          navigate(home)
        }
      })
      .catch((error) => console.log('error signing in:', error))
      .finally(() => {
        setLoading(false)
      })
  }

  const handleEmailChange = (email) => {
    setEmail(email)
  }

  const handlePasswordChange = (password) => {
    setPassword(password)
  }

  return (
    <AuthLayout>
      <div className='w-80 bg-black p-6 rounded-md text-white'>
        <div className='mb-2'>
          <h1 className='text-4xl font-bold '>Marley</h1>
          <h1 className='mb-4'>Login</h1>
        </div>
        {loading ? (
          '...Loading'
        ) : (
          <LoginForm handleSubmit={handleSubmit} handleEmailChange={handleEmailChange} handlePasswordChange={handlePasswordChange}>
            {' '}
          </LoginForm>
        )}
        <div className='flex align-center'></div>
      </div>
    </AuthLayout>
  )
}
export default Login
