import theme from '@material-tailwind/react/theme'
import React, { useContext, createContext, useEffect, useState } from 'react'
import { useAuth } from './auth'

const themeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const theme = useProvideTheme()
  return <themeContext.Provider value={theme}>{children}</themeContext.Provider>
}

export const useTheme = () => {
  return useContext(themeContext)
}

const useProvideTheme = () => {
  const key = 'theme'
  const [theme, setTheme] = useState(localStorage.getItem('theme'))
  const { user } = useAuth()
  const toggleVenueTheme = () => {
    setTheme('venue')
  }

  const toggleUserTheme = () => {
    setTheme('customer')
  }

  useEffect(() => {
    if (user) {
      setTheme(user.account_type)
    }
  }, [user])

  useEffect(() => {
    localStorage.setItem(key, theme)
    if (theme == 'customer') {
      document.getElementById('root').classList.add('customer')
      document.getElementById('root').classList.remove('venue')
    } else {
      document.getElementById('root').classList.add('venue')
      document.getElementById('root').classList.remove('customer')
    }
  }, [theme])

  return {
    toggleUserTheme,
    toggleVenueTheme
  }
}
