import React from 'react'
import { useNavigate } from 'react-router-dom'

const PaymentCompleted = () => {
  const navigate = useNavigate()
  const k = setTimeout(() => {
    navigate('/venue/payment')
  }, 5000)
  return (
    <div>
      <div className='container flex w-full flex-row text-center justify-center pt-5 ml-7'></div>
      <div className='flex-1 flex flex-col items-center justify-center'>
        <h1 className='text-white'>Payment Completed!</h1>
        <span className='overflow-hidden whitespace-nowrap text-xl animate-typing'>. . . . . . . .</span>
      </div>
    </div>
  )
}

export default PaymentCompleted
