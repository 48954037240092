import React from 'react'
import Application from '../pages/Application'
import Approved from '../components/Approved'
import LoanConfirmation from '../components/LoanConfirmation'
import ApplicationPending from '../components/ApplicationPending'

export const ApplicationRoutes = [
  { path: '/new-application/:sessionId/:totalAmount/:venueName', element: <Application /> },
  { path: '/approved/:sessionId/:totalAmount/:venueName', element: <Approved /> },
  { path: '/pending', element: <ApplicationPending /> },
  { path: '/loanconfirmation/:sessionId/:totalAmount/:venueName', element: <LoanConfirmation /> }
]
