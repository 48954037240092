import React, { useState, useEffect, useMemo } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useAuth } from './auth'
const stripe_api_key = process.env.STRIPE_API_KEY ? process.env.STRIPE_API_KEY : process.env.REACT_APP_STRIPE_API_KEY

export const useStripeAccount = () => {
  const { user } = useAuth()
  const [stripePromise, setStripePromise] = useState( loadStripe(stripe_api_key, { stripeAccount: user?.stripe_account_id }))
    useEffect(() => {
        setStripePromise(loadStripe(stripe_api_key, { stripeAccount: user?.stripe_account_id }))
    },[user])
 return [stripePromise, setStripePromise]
}
