import React, { useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { authRoutes, applicationRoutes } from './public'
import { routeMap } from './protected'
import { useAuth } from '../lib/auth'
import { Home } from '../pages/Home'
export const AppRoutes = () => {
  const auth = useAuth()
  let common = [{ path: '/', element: <Home /> }]
  let routes = []
  if (auth.user) {
    routes = [...routes,...authRoutes, ...routeMap[auth.user.account_type], ...applicationRoutes]
  } else {
    routes = [...routes, ...authRoutes, ...applicationRoutes]
  }
  const elements = useRoutes([...routes, ...common])
  return elements
}

export * from './protected'
