import { NavBar, Sidebar } from '../Elements/Navbar'
import React from 'react'

export const MainLayout = ({ children }) => {
  return (
    <div className='bg-primary h-screen flex md:flex-col overflow-scroll w-screen'>
      <div className='w-1/5 min-w-[300px] md:w-full md:h-1/5'>
        <Sidebar></Sidebar>
      </div>
      <main className='w-4/5 h-screen md:w-full md:h-4/5'>{children}</main>
    </div>
  )
}
