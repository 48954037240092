import React, { useState, useEffect } from 'react'
import Confetti from 'react-confetti'
import LoanOfferDetailsCard from './LoanOfferDetailsCard'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../../../config'
import { useLocation, useParams } from 'react-router-dom'

const Approved = () => {
  const [showConfetti, setShowConfetti] = useState(true)
  const [showLoanInfo, setShowLoanInfo] = useState(false)
  const [loanInformation, setLoanInformation] = useState({})
  const [pageReloaded, setPageReloaded] = useState(false)

  const navigate = useNavigate()

  const location = useLocation()
  const params = useParams()

  const { sessionId, totalAmount, venueName } = params

  const fetchLoanInformation = async () => {
    const { data, error } = await supabase.from('Applications').select('total_loan_amount, apr, term').eq('session_id', sessionId).single()
    if (error) {
    } else {
      setLoanInformation({
        totalAmount: data.total_loan_amount,
        apr: 1000,
        termLength: data.term
      })
    }
  }

  useEffect(() => {
    fetchLoanInformation()
  }, [sessionId, totalAmount]) // Include sessionId and totalAmount as dependencies

  const handleShowLoanInfo = () => {
    setShowLoanInfo(true)
    fetchLoanInformation()
  }

  const handleAcceptLoanOffer = async () => {
    navigate(`/loanconfirmation/${sessionId}/${totalAmount}/${venueName}`)

    const { data, error } = await supabase.from('Applications').update({ accepted_offer: 'Yes' }).eq('session_id', sessionId)

    window.location.reload()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false)
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  const formattedTotalAmount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(loanInformation.totalAmount)

  return (
    <div className='flex flex-col h-screen w-screen text-white'>
      {showConfetti && <Confetti />}
      <div className='flex flex-col text-white'></div>
      <div className='flex flex-col items-center justify-center flex-grow'>
        <div className='text-center text-white'>
          <h1 className='text-2xl font-bold mb-4'>{"You're approved!"}</h1>
          {!showLoanInfo && (
            <button className='bg-muted-purple text-white py-2 px-4 rounded mb-4' onClick={handleShowLoanInfo}>
              Show Loan Information
            </button>
          )}
          {showLoanInfo && (
            <div className='bg-white text-gray-800 p-4 rounded shadow-lg mb-4 w-full sm:w-[500px] min-w-[20rem] sm:h-[350px] lg:h-[250px]'>
              <h2 className='text-xl font-bold mb-2 text-left'>Loan Offer Details</h2>
              <LoanOfferDetailsCard key={Math.random} loanInfo={loanInformation} />{' '}
            </div>
          )}
          {showLoanInfo && (
            <button className='bg-muted-purple font-semibold w-full text-white py-2 px-4 rounded h-[60px]' onClick={handleAcceptLoanOffer}>
              {'Accept Loan Offer'}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Approved
