import React from 'react'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import LoansView from '../features/venue/pages/LoansView'
import { Spinner } from '../components/Elements/Spinner'
import { MainLayout } from '../components/Layouts'
import Dashboard from '../features/venue/pages/Dashboard'
import ManageAccount from '../features/user/pages/ManageAccount'
import AutoPayPage from '../features/user/pages/AutopayPage'
import Profile from '../features/user/pages/Profile'
import PaymentPage from '../features/user/pages/PaymentPage'
import Payment from '../features/venue/pages/Payment'
import PaymentCompleted from '../features/venue/pages/PaymentCompleted'
import InsuranceFiller from '../features/venue/pages/InsuranceFiller'
import { FaTable, FaDollarSign, FaCreditCard, FaShieldAlt, FaHome } from 'react-icons/fa'
import { FaGear } from 'react-icons/fa6'
export const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className='h-full w-full flex items-center justify-center'>
            <Spinner size='xl' />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  )
}

export const userRoutes = [
  {
    path: 'user',
    element: <App />,
    children: [
      {
        path: 'profile',
        element: <Profile />,
        showInNav: true,
        renameInNav: 'Home',
        postion: 'middle',
        Icon: <FaHome className='mr-2 text-white' />
      },
      {
        path: 'payments',
        element: <PaymentPage />,
        showInNav: false,
        position: 'middle',
        Icon: <FaDollarSign className='mr-2 text-white' />
      },
      {
        path: 'autopay',
        element: <AutoPayPage />,
        renameInNav: 'Autopay'
      },
      {
        path: 'account',
        element: <ManageAccount />,
        showInNav: true,
        renameInNav: 'Account Info',
        position: 'middle',
        Icon: <FaGear className='mr-2 text-white' />
      }
    ]
  }
]
export const venueRoutes = [
  {
    path: 'venue',
    element: <App />,
    showInNav: false,
    renameInNav: 'home',
    Icon: <FaHome className='mr-2 text-purple-300' />,
    children: [
      {
        path: 'financing',
        element: <Dashboard />,
        renameInNav: 'Financing',
        showInNav: true,
        position: 'middle',
        Icon: <FaDollarSign className='mr-2 text-white' />
      },
      {
        path: 'payment',
        showInNav: true,
        renameInNav: 'Payments',
        element: <Payment />,
        position: 'middle',
        Icon: <FaCreditCard className='mr-2  text-white' />,
        children: [{ path: 'completed', element: <PaymentCompleted /> }]
      },
      {
        path: 'insurance',
        showInNav: true,
        renameInNav: 'Insurance',
        element: <InsuranceFiller />,
        position: 'middle',
        Icon: <FaShieldAlt className='mr-2 text-white' />
      },
      {
        path: 'status',
        showInNav: true,
        renameInNav: 'View Status',
        element: <LoansView />,
        position: 'below'
      }
    ]
  }
]

// {name: (string) name of nav item, link: string (full path of link), showInNav: boolean (should show) }

export const getNavLinks = (userType) => {
  let routeList = []
  if (userType !== undefined) {
    routeList = navHelper(routeMap[userType], '')
  }

  return routeList.filter((navRoute) => navRoute.show)
}

const navHelper = (routes, prev) => {
  const currentRoutes = []
  if (routes === undefined) {
    return []
  } else {
    routes.forEach((routeItem) => {
      const { path, showInNav, children, Icon, position } = routeItem
      const curItem = { name: rename(routeItem), link: prev + '/' + path, show: showInNav, Icon, position }
      currentRoutes.push(curItem, ...navHelper(children, curItem['link']))
    })
    return currentRoutes
  }
}

const rename = (route) => {
  return route['renameInNav'] !== undefined ? route['renameInNav'] : route['path']
}

export const getHomeFromUser = (user) => {
  let home
  if (user != undefined) {
    home = user.account_type === 'venue' ? '/venue/financing' : '/user/profile'
  } else {
    home = '/auth/login'
  }
  return home
}

export const routeMap = { customer: userRoutes, venue: venueRoutes }
