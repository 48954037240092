import React, { useNavigate } from 'react-router-dom'

/* 
dummy page
*/
export const Home = () => {
  const navigate = useNavigate()

  const k = setTimeout(() => {
    navigate('/auth/login')
  }, 3500)
  return (
    <div className='flex h-screen flex-col items-center justify-center overflow-hidden w-screen'>
      <h1 className='text-4xl font-bold text-black mb-4'>Marley Finance</h1>
      <div className='mt-4 w-16 h-16 border-t-2 border-blue-500 border-solid rounded-full animate-spin'></div>
    </div>
    // <div className='h-screen w-screen flex items-center justify-center'>
    //   <iframe className='aspect-[9/16] border-solid border-2 border-purple-100 rounded-lg' src='https://marleyfinance.com/' />
    // </div>
  )
}
