import React, { useEffect } from 'react'
import RepaymentProgress from '../components/RepaymentProgress'
import PaymentInfoCard from '../components/PaymentInfoCard'
import AutoPay from '../components/Autopay'
import { useAuth } from '../../../lib/auth'

export const Profile = () => {
  const { user, refreshUser } = useAuth()

  useEffect(() => {
    refreshUser()
  }, [])

  return (
    <div className='flex-1 flex mx-auto items-center justify-center'>
      <div className='max-w-[550px] w-full px-4 md:px-8 mt-[80px]'>
        <section>
          <div className='flex flex-col text-black align-center'>
            <div className='w-full mx-auto text-center'>
              <RepaymentProgress user={user} />
              <PaymentInfoCard user={user} />
            </div>
            <AutoPay user={user} />
          </div>
        </section>
      </div>
    </div>
  )
}
export default Profile
