import React, { useState } from 'react'
import BasicInfoForm from '../components/BasicInfoForm'
import { useParams, useNavigate } from 'react-router-dom'

const Application = () => {
  const [isApproved, setIsApproved] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [applicationStatus, setApplicationStatus] = useState()

  const navigate = useNavigate()
  const params = useParams()

  const { sessionId, totalAmount, venueName } = params

  const handleApplicationStatusChange = (value) => {
    setApplicationStatus(value)
  }

  const handleSubmit = async (applicationStatus) => {
    if (applicationStatus === 'approved') {
      setIsApproved(true)
      setIsSubmitted(true)
    } else {
      setIsApproved(false)
    }
  }

  const handlePageRefresh = () => {
    window.location.reload()
  }

  if (!isSubmitted) {
    return <BasicInfoForm onSubmit={handleSubmit} onApplicationStatusChange={handleApplicationStatusChange} />
  } else {
    return (
      <>
        {isApproved && isSubmitted
          ? navigate(`/approved/${sessionId}/${totalAmount}/${venueName}`)
          : navigate(`/pending/${sessionId}/${totalAmount}/${venueName}`)}
      </>
    )
  }
}

export default Application
