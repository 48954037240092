import React from 'react'
import { ContentLayout } from '../../../components/Layouts/ContentLayout'
import { useAuth } from '../../../lib/auth'
import MyAccountCard from '../components/cards/MyAccountCard'
import PaymentsCard from '../components/cards/PaymentsCard'

const ManageAccount = () => {
  const { user } = useAuth()
  return (
    <ContentLayout>
      <div className='flex flex-col'>
        <MyAccountCard user={user} />
        <PaymentsCard color='light' user={user} />
      </div>
    </ContentLayout>
  )
}

export default ManageAccount
