import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../lib/auth'
import RepaymentStatusCard from '../components/cards/RepaymentStatusCard'

const LoansView = () => {
  const [loans, setLoans] = useState([])
  const { user } = useAuth()
  useEffect(() => {
    if (user && user.venue_loan_status) {
      setLoans(user.venue_loan_status)
      console.log(user, user.venue_loan_status)
    }
  }, [user])

  return (
    <div className='flex flex-col h-full w-full'>
      <div className='flex w-full flex-row text-center justify-center pt-5 ml-7'></div>
      <div className='flex-1 flex flex-col items-center justify-center'>
        {/* <h1 className='text-white text-2xl' style={{ marginTop: "200px" }}>Loan View</h1> */}
        <div className='grid grid-cols-1 gap-5'>
          {loans.map((loan, index) => (
            <RepaymentStatusCard key={index} loan={loan} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default LoansView
