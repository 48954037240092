import React from 'react'

const ApplicationPending = () => {
  return (
    <div className='flex flex-col h-screen w-screen text-white'>
      <div className='flex flex-col text-white'></div>
      <div className='flex flex-col items-center justify-center flex-grow'>
        <div className='text-center text-white'>
          <h1 className='text-2xl font-bold mb-4'>Your application is pending</h1>
          <p className='mb-4'>We&apos;re currently reviewing your application. You will be notified once the review process is complete.</p>
        </div>
      </div>
    </div>
  )
}

export default ApplicationPending
