import React, { useState, useEffect } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { post } from '../../../lib/fetch'
import CheckoutForm from '../components/CheckoutForm'
import { Outlet } from 'react-router-dom'
import { useAuth } from '../../../lib/auth'
import { useStripeAccount } from '../../../lib/payements'
import { loadStripe } from '@stripe/stripe-js'

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.

export default function Payment() {
  const [clientSecret, setClientSecret] = useState('')
  const [showInputField, setShowInputField] = useState(false)
  const [hide, setHide] = useState(false)
  const [showCheckout, setShowCheckout] = useState(false)
  const [totalPaymentAmount, setTotalPaymentAmount] = useState()
  const { user } = useAuth()
  const [stripePromise, setStripePromise] = useStripeAccount()

  useEffect(() => {
    const redirected = new URLSearchParams(window.location.search).get('redirect_status')

    setHide(redirected !== null)
  })

  // make in  to a hook called useStripe
  const stripeAccount = user.stripe_account_id

  const createPaymentIntent = (payment) => {
    post('/create-payment-intent', {
      amount: payment * 100,
      currency: 'usd',
      automatic_payment_methods: { enabled: true },
      stripeAccount: stripeAccount
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error('Network response was not ok')
        }
        return response.data
      })
      .then((data) => {
        setClientSecret(data.clientSecret)
      })
      .catch((error) => {
        console.error('There was an error!', error)
      })
  }

  const appearance = {
    theme: 'night'
  }
  const options = {
    clientSecret,
    appearance,
    success_url: process.env.REACT_APP_PAYMENT_SUCCESS_REDIRECT
  }
  const toggleInputField = () => {
    setShowInputField(!showInputField)
  }
  const handleContinue = () => {
    setShowCheckout(!showCheckout)
    createPaymentIntent(totalPaymentAmount)
    console.log(totalPaymentAmount)
  }

  return (
    <div className=' flex flex-col justify-center h-full pt-5 w-full'>
      <div className='text-center m-auto  bg-gray-900 p-6 rounded-md text-white '>
        <Outlet />

        {!user?.stripe_account_id && <h1>Payments Not Enabled</h1>}
        {user?.stripe_account_id && (
          <>
            {!showInputField && !hide && (
              <button
                className='bg-transparent border border-pink text-white py-2 px-4 rounded w-full hover:bg-muted-purple transition-colors duration-100'
                onClick={toggleInputField}
              >
                Accept New Payment
              </button>
            )}
            {showInputField && !showCheckout && !hide && (
              <>
                <input
                  type='number'
                  placeholder=''
                  value={totalPaymentAmount}
                  onChange={(e) => setTotalPaymentAmount(e.target.value)}
                  className='w-full bg-gray-800 border text-white py-2 px-3 rounded mb-4 mt-2 text-center'
                />
                <button onClick={handleContinue} className='bg-blue-400 text-white py-2 px-4 rounded w-full mt-3'>
                  Continue
                </button>
              </>
            )}

            <div className='items-center justify-center'>
              {showCheckout && (
                <div className=' align-center text-center p-6 rounded-md text-white'>
                  {' '}
                  <div className='flex-1 flex mx-auto items-center justify-center'></div>
                  {clientSecret && (
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutForm />
                    </Elements>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
