import { supabase } from '../config/supabaseClient'

export async function fetchUserProfile(id) {
  const { data, error } = await supabase.from('profiles').select('*').eq('id', id)

  if (error) {
    console.error('Error fetching user profile:', error)
    return error
  } else {
    return data
  }
}

export async function updatePlaidAccountInformation(userId, accountInfo) {
  const { error } = await supabase
    .from('profiles')
    .update({
      plaid_account_information: accountInfo
    })
    .eq('id', userId)

  if (error) {
    console.error('Error updating Plaid account information:', error)
  } else {
    window.location.reload()
    return true
  }
}

export async function updatePlaidAccountDetails(userId, accountDetails) {
  const { error } = await supabase
    .from('profiles')
    .update({
      plaid_account_details: accountDetails
    })
    .eq('id', userId)

  if (error) {
    return error
  } else {
    return true
  }
}
export async function upsertPaymentInformation(user, paymentInformation) {
  return await supabase.from('profiles').upsert(
    {
      id: user.id,
      payment_status: 'Submitted',
      payment_history: Array.isArray(user.payment_history) ? [...user.payment_history, paymentInformation] : [paymentInformation]
    },
    { onConflict: 'id' }
  )
}
