import React, { useEffect, useState, useCallback } from 'react'
import PaymentDetails from '../components/PaymentDetails'

import { usePaymentHooks, getLinkToken } from '../../../services/LoanPaymentService'
import { ContentLayout } from '../../../components/Layouts/ContentLayout'
function PaymentPage() {
  const { open, ready, user } = usePaymentHooks()
  const plaidAccountDetails = user.plaid_account_details
  const plaidAccountInformation = user.plaid_account_information

  const paymentDetailsProps = {
    open,
    ready,
    plaidAccountDetails,
    plaidAccountInformation,
    user
  }

  return (
    <ContentLayout>
      <div className='bg-white shadow-md rounded px-8 lg:pt-6 pb-8 mb-4 lg:w-[480px] lg:h-[550px]'>
        <h1 className='font-bold text-xl mb-2 text-left'>Payment Info</h1>
        <PaymentDetails {...paymentDetailsProps} />
      </div>
    </ContentLayout>
  )
}

export default PaymentPage
