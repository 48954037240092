import { useState, useEffect, useCallback } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { post } from '../lib/fetch'
import { updatePlaidAccountDetails, updatePlaidAccountInformation } from './DataAccess'
import { useAuth } from '../lib/auth'

/*
 hits create_link_token endpoint and returns token
*/
export const getLinkToken = async () => {
  return await post('create_link_token')
    .then((response) => {
      return response.data.link_token
    })
    .catch((error) => {
      console.log('error creating link token', error)
      return error
    })
}

/*
does the dance with creating a link token 
then calls plaids hook and exchanges token on success
*/
export function usePaymentHooks() {
  const [linkToken, setLinkToken] = useState()
  const [publicToken, setPublicToken] = useState()
  const { user } = useAuth()

  useEffect(() => {
    async function fetchLinkToken() {
      const data = await getLinkToken() // Call your function from PaymentService
      setLinkToken(data)
    }
    fetchLinkToken()
  }, [])

  const onExit = useCallback((error, metadata) => {
    if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
      window.location.reload()
    }
    // to handle other error codes, see https://plaid.com/docs/errors/
  }, [])

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onExit: onExit,
    onSuccess: (public_token, metadata) => {
      setPublicToken(public_token)
    }
  })

  useEffect(() => {
    async function fetchData() {
      if (publicToken) {
        let accessToken = await post('/exchange_public_token', {
          public_token: publicToken
        })
        const auth = await post('/auth', {
          access_token: accessToken.data.accessToken
        })
        await updatePlaidAccountInformation(user.id, auth.data.accounts[0])
        await updatePlaidAccountDetails(user.id, auth.data.numbers.ach[0])
      }
    }
    fetchData()
  }, [publicToken])

  return {
    open,
    ready,
    user
  }
}
