import React, { useState, useEffect } from 'react'
import { supabase } from '../../../config'
import { useAuth } from '../../../lib/auth'
import { NumberInputCard } from '../../../components/Elements/Input/NumberInputCard'
import { MessagingService } from '../../../services'
const Dashboard = () => {
  const [loanLinkParams, setLoanLinkParams] = useState({
    totalAmount: '',
    sessionID: Date.now().toString(),
    venueName: ''
  })
  const { user } = useAuth()
  const [applicationLink, setApplicationLink] = useState(null)
  const [showInputField, setShowInputField] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [showSendTextField, setShowSendTextField] = useState(false)

  useEffect(() => {
    if (applicationLink !== null) {
    }
  }, [applicationLink])

  const handleCreateApplicationLink = async () => {
    const sessionID = Date.now().toString()
    setTimeout(setLoanLinkParams({ ...loanLinkParams, sessionID: sessionID }), 50)

    try {
      // responsibility of backend
      const { data, error } = await supabase.from('Applications').insert([
        {
          session_id: loanLinkParams.sessionID,
          venue_name: user.username,
          total_loan_amount: loanLinkParams.totalAmount.toString()
        }
      ])

      if (error) {
        console.error('Error creating application:', error)
      } else {
        const link =
          'https://application.marleyfinance.com' +
          '/new-application/' +
          loanLinkParams.sessionID +
          '/' +
          loanLinkParams.totalAmount +
          '/' +
          user.username.replace(/\s/g, '')

        setApplicationLink(link)
        setShowInputField(false) // Hide the input field and button
      }
    } catch (error) {
      console.error(error)
    }
  }

  const toggleInputField = () => {
    setShowInputField(!showInputField)
    setShowSendTextField(false)
    setPhoneNumber('')
  }

  const toggleSendTextField = () => {
    setShowSendTextField(!showSendTextField)
  }

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(applicationLink)
    alert('Link copied to clipboard')
  }
  // responsibility of backend
  const handleSendText = async () => {
    MessagingService.sendText(phoneNumber, applicationLink)
      .then((r) => {
        console.log(r)
        alert('Message Sent')
        setShowInputField(!showInputField)
        setShowSendTextField(false)
        setPhoneNumber('')
      })
      .catch((error) => {
        console.error('Error sending message:', error)
        alert('Error sending message')
      })
  }

  return (
    <div className='flex flex-col justify-items-center justify-center h-full w-full  text-white'>
      <div className='container flex w-full flex-row text-center justify-center pt-5 ml-7'></div>
      <div className='flex-1 flex flex-col items-center justify-center'>
        <div className='w-80 align-center text-center bg-gray-900 p-6 rounded-md text-white'>
          <button
            className='bg-transparent border border-pink text-white py-2 px-4 rounded w-full hover:bg-pink transition-colors duration-500'
            onClick={toggleInputField}
          >
            Create New Application
          </button>

          {showInputField && (
            <div className='mb-4'>
              <h1 className='mt-5 mb-2'>Total Amount to Finance ($)</h1>
              <NumberInputCard
                type='number'
                placeholder=''
                value={loanLinkParams.totalAmount}
                onChange={(newValue) =>
                  setLoanLinkParams({
                    ...loanLinkParams,
                    totalAmount: newValue
                  })
                }
                className='w-full bg-gray-800 border text-white py-2 px-3 rounded mb-4 mt-2 text-center'
              />
              <div className=''>
                <button className='bg-blue-400 text-white py-2 px-4 rounded w-full mt-3' onClick={handleCreateApplicationLink}>
                  Generate Link
                </button>
              </div>
            </div>
          )}
        </div>
        <div className='mb-2'></div>
        {!showInputField && applicationLink && (
          <div className='m-3 '>
            <div className='flex flex-col items-start text-center bg-gray-900 p-6 rounded-md text-white sm:text-sm w-full'>
              <a href={applicationLink} className=''>
                <strong>
                  <u>{applicationLink}</u>
                </strong>
              </a>
              <div className='flex mt-4 mr-5'>
                <button className='w-[150px] h-[35px] bg-[#50BEFD] mx-5 rounded-md p-1' onClick={handleCopyToClipboard}>
                  Copy to Clipboard
                </button>
                {/* to send text message */}
                {/* to send text message */}
                <button className='w-[150px] h-[35px] bg-[#ea6a91] mx-5 rounded-md mx-5 p-1' onClick={toggleSendTextField}>
                  Text Link
                </button>
                {showSendTextField && (
                  <div className=''>
                    <input
                      type='text'
                      placeholder='Enter phone number'
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className='bg-gray-800 text-white py-2 px-3 rounded mb-2 mr-3 text-center'
                    />
                    <button className='bg-green-400 text-white py-2 px-4 rounded' onClick={handleSendText}>
                      Send
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Dashboard
