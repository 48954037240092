import React, { useState } from 'react'
import { useAuth } from '../../../lib/auth'
import { getNavLinks } from '../../../routes'
import { useNavigate } from 'react-router-dom'
// nav object

// {name: (string) name of nav item, link: string (full path of link) }

export const NavBar = () => {
  const parentWebsite = 'https://marleyfinance.com/'
  const [showDropdown, setShowDropdown] = useState(false)
  const { signOutAndReturn, user } = useAuth()
  const [navItems, setNavItems] = useState(getNavLinks(user?.account_type))
  const navigate = useNavigate()
  let timeoutId
  const handleMouseEnter = () => {
    clearTimeout(timeoutId)
    setShowDropdown(true)
  }
  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setShowDropdown(false)
    }, 1000)
  }

  return (
    <nav className='w-full flex justify-between items-center py-4 px-6'>
      {user ? (
        <a href='/' className='text-4xl font-bold text-white'>
          {user.first_name}
        </a>
      ) : (
        <a href={parentWebsite} className='text-4xl font-bold text-white'>
          Marley
        </a>
      )}

      <div>
        {user ? (
          <div className='relative' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <button className='button block text-white' style={{ right: 0 }}>
              {user.username}
            </button>
            {showDropdown && (
              <div className='absolute bg-white shadow-md mt-2 rounded-md w-[155px]' style={{ right: 0 }}>
                {navItems?.map((navitem, i) => (
                  <button key={i} className='block text-left px-4 py-2' onClick={() => navigate(navitem['link'])}>
                    {navitem['name']}
                  </button>
                ))}
                {/* <button
                                    className="block text-left px-4 py-2"
                                    onClick={() =>
                                        (window.location.href = '/loans-view')
                                    }
                                >
                                    View Loans
                                </button>
                                <button
                                    className="block w-full text-left px-4 py-2"
                                    onClick={() => signOut()}
                                >
                                    Sign Out
                                </button> */}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </nav>
  )
}
