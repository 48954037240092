import React from 'react'
import { NavBar } from '../Elements/Navbar'

export const AuthLayout = ({ children }) => {
  return (
    <div className='App h-screen flex flex-col w-screen items-center bg-gradient-to-r from-[#7D79FF] to-[#E75881]'>
      <NavBar />
      <main className='m-auto'>{children}</main>
    </div>
  )
}
