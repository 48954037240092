import React, { useState } from 'react'

function LoginForm({ handleSubmit, handlePasswordChange, handleEmailChange }) {
  const [error, setError] = useState(null)

  return (
    <form onSubmit={handleSubmit}>
      <label className='block text-white mb-2' htmlFor='email'>
        Email
      </label>
      <input
        className={`w-full bg-gray-900 border border-gray-700 text-white py-2 px-3 rounded mb-4`}
        type='email'
        name='email'
        onChange={(e) => handleEmailChange(e.target.value)}
      />
      <label className='block text-white mb-2' htmlFor='password'>
        Password
      </label>
      <input
        className={`w-full bg-gray-900 border border-gray-700 text-white py-2 px-3 rounded mb-4`}
        type='password'
        name='password'
        onChange={(e) => handlePasswordChange(e.target.value)}
      />
      <button className='bg-blue-600 text-white py-2 px-4 rounded w-full'> Login </button>
      {error && <div className='text-red-500 text-xs'>{error.message}</div>}
      <div className='pt-4 pb-2'></div>
    </form>
  )
}

export default LoginForm
