import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../lib/auth'
import { supabase } from '../../../config'

export const InsuranceFiller = () => {
  const { user } = useAuth()

  const [insuranceLink, setInsuranceLink] = useState()

  useEffect(() => {
    if (user && user.insurance_link) {
      setInsuranceLink(user.insurance_link)
    }
  }, [])

  return (
    <div className='flex flex-col justify-items-center justify-center h-full w-full  text-white'>
      <div className='container flex w-full flex-row text-center justify-center pt-5 ml-7 '>
        <div className='w-80 align-center text-center bg-gray-900 p-6 rounded-md text-white w-auto'>
          <h1 className='text-xl font-bold mb-2'>Event Insurance</h1>
          {!insuranceLink && <p>Event insurance is not enabled. Please contact to onboard.</p>}
          {insuranceLink && (
            <a className='underline' target='_blank' rel='noreferrer' href={`${insuranceLink}`}>
              {insuranceLink}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
export default InsuranceFiller
