import { AppRoutes } from './routes'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from '../src/lib/auth'
import { ThemeProvider } from '../src/lib/ThemeProvider'
import React from 'react'

function App() {
  return (
    <BrowserRouter className=''>
      <AuthProvider>
        <ThemeProvider>
          <AppRoutes />
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
