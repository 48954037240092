import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './Login'
import { useAuth } from '../../../lib/auth'

export const AuthRoutes = () => {
  const { signOut } = useAuth()
  return (
    <Routes>
      <Route path='/login' action={signOut()} element={<Login />} />
    </Routes>
  )
}
