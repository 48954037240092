import { post } from '../lib/fetch'

const accountSid = 'AC96a130c183eaad3e7e0d1221ddafc484'
const authToken = '48ccfd6bece96f3bde721dad53c4d099'

const sendText = async (phoneNumber, applicationLink) => {
  const twilioEndpoint = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`
  const textBody = 'Click ' + applicationLink + ' to apply for financing with Marley'
  const data = new URLSearchParams()
  data.append('To', phoneNumber)
  data.append('From', '+18777295203')
  data.append('Body', textBody)
  return await post(twilioEndpoint, data, {
    auth: {
      username: accountSid,
      password: authToken
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
export const MessagingService = { sendText }
