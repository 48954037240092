// Sidebar.js
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../lib/auth'
import { getNavLinks, getHomeFromUser } from '../../../routes'

import { useNavigate, Link } from 'react-router-dom'

export const Sidebar = () => {
  const { signOutAndReturn, user } = useAuth()
  const [navItems, setNavItems] = useState(getNavLinks(user.account_type))
  const [cols, setCols] = useState(3)
  useEffect(() => {
    setCols(
      navItems.filter((item) => {
        return item?.position != 'below'
      }).length
    )
  }, [navItems])
  const navigate = useNavigate()
  const home = getHomeFromUser(user)
  return (
    <div className='flex flex-col md:bg-secondary justify-items-center justify-center h-full w-full pattern md:flex-row md:justify-normal md:justify-items-normal'>
      <div className='z-20 flex justify-around md:bg-primary md:rounded-none rounded-lg md:w-full md:flex-row '>
        <div className=' md:grid md:grid-rows-2 bg-gray-900 p-8  rounded-lg shadow  md:w-full w-[260px]'>
          <div>
            <Link to={home} className='text-4xl font-bold text-white'>
              Marley
            </Link>
            {user?.first_name && <h1 className='text-white mt-1  md:hidden'>Hi, {user?.first_name}</h1>}
            {!user?.first_name && <h1 className='text-white mt-1 md:hidden'> {user?.username}</h1>}
          </div>
          {/* dynamic classes are a no no, safelisting in tailwindconfig */}
          <div className={`grid md:grid md:grid-rows-2 md:grid-cols-${cols}  md:justify-items-start w-full`}>
            <div className='flex bg-black border md:flex-row  md:hidden border-grey my-2 md:m-0'></div>
            {/* navitem{name:string, link:string, show:bool, icon:element} */}
            {navItems
              .filter((item) => {
                return item.position !== 'below'
              })
              .map(({ name, link, Icon }) => (
                <div
                  key={name}
                  className={`flex py-2 mb-2 md:m-0 md:p-0 md:justify-center place-items-center  rounded cursor-pointer duration-100 ${
                    window.location.pathname.endsWith(link)
                      ? 'bg-tertiary md:hover:font-bold md:bg-transparent'
                      : 'hover:bg-secondary md:hover:bg-transparent'
                  }`}
                  onClick={() => navigate(link)}
                >
                  {' '}
                  {Icon}
                  <span className='text-white text-l'> {name}</span>
                </div>
              ))}
            <div className='bg-black border border-grey  md:hidden md:m-0 ' />
            {navItems
              .filter((item) => {
                return item.position == 'below'
              })
              .map(({ name, link, Icon }) => (
                <div
                  key={name}
                  className={`mb-2 py-2 cursor-pointer duration-100  rounded ${window.location.pathname.endsWith(link) ? 'font-bold' : ''}`}
                  onClick={() => navigate(link)}
                >
                  {Icon}
                  <span className='text-white'> {name}</span>
                </div>
              ))}
            <div className='py-2 mb-2' onClick={() => signOutAndReturn()}>
              <h1 className='text-white cursor-pointer duration-100 md:m-0  '>Logout</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
